@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Palette generated by Material Palette - materialpalette.com/light-blue/grey */
.dark-primary-color    { background: #0288D1; }
.default-primary-color { background: #03A9F4; }
.light-primary-color   { background: #B3E5FC; }
.text-primary-color    { color: #FFFFFF; }
.accent-color          { background: #9E9E9E; }
.primary-text-color    { color: #212121; }
.secondary-text-color  { color: #757575; }
.divider-color         { border-color: #BDBDBD; }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: primary-text-color ;
}

/* Utilities */
.container {
  max-width: 3000px;
  margin: auto;
  overflow: hidden;
  padding: 0 1rem;
}

/* Center All */
.all-center {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  margin: auto;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
}

/* Cards */
.card {
  padding: 1rem;
  border: #ccc 1px dotted;
  margin: 1rem 0;
}

@font-face {
  font-family: 'icomoon';
  src:  url(/static/media/icomoon.fddc9625.eot);
  src:  url(/static/media/icomoon.fddc9625.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.0af9695e.ttf) format('truetype'),
    url(/static/media/icomoon.c24160e6.woff) format('woff'),
    url(/static/media/icomoon.5395d3e2.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-list-solid-minus:before {
  content: "\e90b";
}
.icon-list-solid-plus:before {
  content: "\e90c";
}

.home-page-top-margin {
  margin-top: 5rem;
}

.home-page-bottom-margin {
  margin-bottom: 2rem;
}
