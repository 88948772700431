@import url('https://fonts.googleapis.com/css?family=Roboto');

/* Palette generated by Material Palette - materialpalette.com/light-blue/grey */
.dark-primary-color    { background: #0288D1; }
.default-primary-color { background: #03A9F4; }
.light-primary-color   { background: #B3E5FC; }
.text-primary-color    { color: #FFFFFF; }
.accent-color          { background: #9E9E9E; }
.primary-text-color    { color: #212121; }
.secondary-text-color  { color: #757575; }
.divider-color         { border-color: #BDBDBD; }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: primary-text-color ;
}

/* Utilities */
.container {
  max-width: 3000px;
  margin: auto;
  overflow: hidden;
  padding: 0 1rem;
}

/* Center All */
.all-center {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Cards */
.card {
  padding: 1rem;
  border: #ccc 1px dotted;
  margin: 1rem 0;
}

@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?akqzub');
  src:  url('fonts/icomoon.eot?akqzub#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?akqzub') format('truetype'),
    url('fonts/icomoon.woff?akqzub') format('woff'),
    url('fonts/icomoon.svg?akqzub#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-list-solid-minus:before {
  content: "\e90b";
}
.icon-list-solid-plus:before {
  content: "\e90c";
}

.home-page-top-margin {
  margin-top: 5rem;
}

.home-page-bottom-margin {
  margin-bottom: 2rem;
}